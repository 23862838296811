import { default as imprint7kSS69wvmJMeta } from "/vercel/path1/pages/imprint.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as privacyXnmh8a8hSIMeta } from "/vercel/path1/pages/privacy.vue?macro=true";
import { default as termsmyl6hCuvzxMeta } from "/vercel/path1/pages/terms.vue?macro=true";
import { default as welcomeuYs8OlHLQaMeta } from "/vercel/path1/pages/welcome.vue?macro=true";
import { default as component_45stubUB6lFFs5oDMeta } from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubUB6lFFs5oD } from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/vercel/path1/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/vercel/path1/pages/imprint.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    component: () => import("/vercel/path1/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/vercel/path1/pages/privacy.vue")
  },
  {
    name: "terms___de",
    path: "/agb",
    component: () => import("/vercel/path1/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/vercel/path1/pages/terms.vue")
  },
  {
    name: "welcome___de",
    path: "/willkommen",
    component: () => import("/vercel/path1/pages/welcome.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    component: () => import("/vercel/path1/pages/welcome.vue")
  },
  {
    name: component_45stubUB6lFFs5oDMeta?.name,
    path: "/anmelden",
    component: component_45stubUB6lFFs5oD
  },
  {
    name: component_45stubUB6lFFs5oDMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubUB6lFFs5oD
  },
  {
    name: component_45stubUB6lFFs5oDMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubUB6lFFs5oD
  },
  {
    name: component_45stubUB6lFFs5oDMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubUB6lFFs5oD
  },
  {
    name: component_45stubUB6lFFs5oDMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubUB6lFFs5oD
  }
]