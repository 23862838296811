import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import { withScope } from '@sentry/vue';
import type { Router } from 'vue-router';
import { CaptureConsole } from '@sentry/integrations';

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    if (!nuxtApp.$config.public.sentryDsn) {
      return {
        provide: {
          sentrySetContext: (
            _name: string,
            _context: {
              [key: string]: any;
            } | null
          ) => {},
          sentrySetUser: (_user: User | null) => {},
          sentrySetTag: (_key: string, _value: Primitive) => {},
          sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
          sentryCaptureException: (_exception: any, _captureContext?: CaptureContext) => {},
        },
      };
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.sentryDsn,
      environment: process.env.NODE_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router, {
            routeLabel: 'path',
          }),
        }),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context);
        Sentry.captureException(err);
      });
    };

    nuxtApp.hook('app:error', (err) => {
      Sentry.captureException(err);
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  },
});
