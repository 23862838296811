<script setup lang="ts">
import { onMounted } from 'vue';

const { title } = useAppConfig();
const config = useRuntimeConfig();

import { registerGclid } from '~/utils/gclid';

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${title}` : title;
  },
});

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

onMounted(() => {
  registerGclid();
});
</script>

<template>
  <Html :lang="head.htmlAttrs!.lang" :dir="head.htmlAttrs!.dir">
    <Head>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta
        v-for="meta in head.meta"
        :id="meta.id"
        :key="meta.id"
        :property="meta.property"
        :content="meta.content"
      />
    </Head>

    <Body>
      <NuxtLoadingIndicator />
      <NuxtLayout>
        <div id="top" class="font-sans">
          <Header />
          <NuxtPage />
          <Footer />
        </div> </NuxtLayout
    ></Body>
  </Html>
</template>
