<template>
  <footer class="border-t border-gray-100/20 py-10 text-sm bg-primary">
    <div class="container">
      <nav aria-label="Footer" class="flex flex-wrap justify-center">
        <div v-for="item in navigation.main" :key="item.name" class="px-2 md:px-5 py-2">
          <a
            v-if="item.onclick"
            href="#"
            @click.prevent="item.onclick"
            class="text-gray-100 hover:text-action"
            >{{ item.name }}</a
          >

          <NuxtLink v-else :to="item.to" class="text-gray-100 hover:text-action">
            {{ item.name }}
          </NuxtLink>
        </div>
      </nav>
      <p class="mt-8 text-center text-gray-100">
        &copy; {{ new Date().getFullYear() }} More at Home GmbH. Alle Rechte vorbehalten.
      </p>
    </div>
  </footer>
</template>

<script setup>
const { t } = useI18n();
const localePath = useLocalePath();

const navigation = computed(() => ({
  main: [
    { name: t('home'), to: localePath('/') },
    { name: t('Privacy Policy'), to: localePath('privacy') },
    { name: t('Terms'), to: localePath('terms') },
    {
      name: t('Cookie Settings'),
      onclick: () => {
        CCM.openWidget();
        return false;
      },
    },
    { name: t('imprint'), to: localePath('imprint') },
  ],
}));
</script>
