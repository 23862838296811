import revive_payload_client_ctFyEPGxPj from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d9tb7iY56d from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8yhxRwwcCZ from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TmPtsZofp0 from "/vercel/path1/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bXQzEpS8tL from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Uei5eEfYAK from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uFYUuNHCN1 from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4cqBX78KIE from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZbTBLhGZIW from "/vercel/path1/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.24.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_wDUfFDlcf1 from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IfcldbFAwz from "/vercel/path1/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/vercel/path1/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_IQfzCtQ60I from "/vercel/path1/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.24.0_vue@3.5.12/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/vercel/path1/plugins/sentry.client.ts";
export default [
  revive_payload_client_ctFyEPGxPj,
  unhead_d9tb7iY56d,
  router_8yhxRwwcCZ,
  _0_siteConfig_TmPtsZofp0,
  payload_client_bXQzEpS8tL,
  navigation_repaint_client_Uei5eEfYAK,
  check_outdated_build_client_uFYUuNHCN1,
  chunk_reload_client_4cqBX78KIE,
  plugin_vue3_ZbTBLhGZIW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wDUfFDlcf1,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  plugin_IQfzCtQ60I,
  sentry_client_shVUlIjFLk
]